import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { CommonContext } from '../../contexts';

const InfoManagers = () => {
  const { categories } = useContext(CommonContext);
  const { pathname } = useLocation();

  return (
    <div id="primary" className="content-area">
      {/* <!--s:#content --> */}
      <div id="content" className="site-content">
        <article>
          <div>
            <div className="title-inner">
              <img src="/common/images/title.png" alt="" />
              <h1>{categories.find((category) => category.categoryCode === pathname.split('/')[4])?.categoryName}</h1>
            </div>
            <div style={{ height: 20 }}></div>
            <div style={{ clear: 'both' }}></div>
            <div className="title_subpage_solid_1"></div>
            <div style={{ height: 30 }}></div>

            <div className="greetings_wrap">
              <div className="table_wrap">
                <table className="tbl_basic">
                  <colgroup>
                    <col />
                    <col style={{ width: 150 }} />
                    <col />
                    <col style={{ width: 100 }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>직 책</th>
                      <th>성 명</th>
                      <th>사무실</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>합회장</th>
                      <td rowSpan="3">남시창</td>
                      <td rowSpan="3">(053)654-1767</td>
                      <td rowSpan="3"></td>
                    </tr>
                    <tr>
                      <th>목회부장</th>
                    </tr>
                    <tr>
                      <th>교육부장</th>
                    </tr>

                    <tr>
                      <th>비서</th>
                      <td>임은주</td>
                      <td>(053)654-1767</td>
                      <td></td>
                    </tr>

                    <tr>
                      <th>총무</th>
                      <td rowSpan="3">배순균</td>
                      <td rowSpan="3">(053)654-1762</td>
                      <td rowSpan="3"></td>
                    </tr>
                    <tr>
                      <th>종교자유부장</th>
                    </tr>
                    <tr>
                      <th>목회부부장</th>
                    </tr>

                    <tr>
                      <th>총무부과장</th>
                      <td>임은주</td>
                      <td>(053)654-1762</td>
                      <td></td>
                    </tr>

                    <tr>
                      <th>재무/감사</th>
                      <td rowSpan="3">홍철표</td>
                      <td rowSpan="3">(053)654-1763</td>
                      <td rowSpan="3"></td>
                    </tr>
                    <tr>
                      <th>신탁부장</th>
                    </tr>
                    <tr>
                      <th>청지기부장</th>
                    </tr>

                    <tr>
                      <th>재무차장</th>
                      <td>백춘희</td>
                      <td>(053)654-1763</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th>재무부 현금계</th>
                      <td>이상민</td>
                      <td>(053)654-1761</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th>재무부 사원</th>
                      <td>노진의</td>
                      <td>(053)654-1761</td>
                      <td></td>
                    </tr>

                    <tr>
                      <th>안교선교부장</th>
                      <td>김동섭</td>
                      <td>(053)654-1764</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th>보건복지부장</th>
                      <td>장대기</td>
                      <td>(053)654-1764</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th>디지털선교부장</th>
                      <td>장대기</td>
                      <td>(053)654-1764</td>
                      <td></td>
                    </tr>

                    <tr>
                      <th>안교선교부과장</th>
                      <td rowSpan="2">김진영</td>
                      <td rowSpan="2">(053)654-1764</td>
                      <td rowSpan="2"></td>
                    </tr>
                    <tr>
                      <th>재단법인과장</th>
                    </tr>

                    <tr>
                      <th>청소년부장</th>
                      <td rowSpan="2">오창규</td>
                      <td rowSpan="2">(053)654-1765</td>
                      <td rowSpan="2"></td>
                    </tr>
                    <tr>
                      <th>군봉사부장</th>
                    </tr>

                    <tr>
                      <th>청소년부서기</th>
                      <td>정도연</td>
                      <td>(053)654-1765</td>
                      <td></td>
                    </tr>

                    <tr>
                      <th>어린이부장</th>
                      <td rowSpan="2">오동준</td>
                      <td rowSpan="2">(053)654-1765</td>
                      <td rowSpan="2"></td>
                    </tr>
                    <tr>
                      <th>홍보부장</th>
                    </tr>

                    <tr>
                      <th>어린이부서기</th>
                      <td>정도연</td>
                      <td>(053)654-1765</td>
                      <td></td>
                    </tr>

                    <tr>
                      <th>여성전도부장</th>
                      <td rowSpan="2">임혜선</td>
                      <td rowSpan="2">(053)654-1765</td>
                      <td rowSpan="2"></td>
                    </tr>
                    <tr>
                      <th>가정봉사부장</th>
                    </tr>

                    <tr>
                      <th>여성전도부서기</th>
                      <td>정도연</td>
                      <td>(053)654-1765</td>
                      <td></td>
                    </tr>

                    <tr>
                      <th>출판전도부장</th>
                      <td rowSpan="2">{new Date() >= new Date('2024-10-01') ? '홍철표' : '권오장'}</td> {/* 나중에 이 코드를 보면, 삭제하고 재수정하라 2024-10-01일부터 담당자가 바뀌도록 작성 */}
                      <td rowSpan="2">(053)654-1766</td>
                      <td rowSpan="2"></td>
                    </tr>
                    <tr>
                      <th>예언의신부장</th>
                    </tr>
                    <tr>
                      <th>출판전도차장</th>
                      <td>박병하</td>
                      <td>(053)654-1768</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th>출판전도과장</th>
                      <td>임은주</td>
                      <td>(053)654-1766</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th>서회총무</th>
                      <td>서대영</td>
                      <td>(053)654-1768</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* <!-- .entry-content --> */}
        </article>
      </div>
      {/* <!--//e: #content --> */}
    </div>
  );
};

export default InfoManagers;
