//prettier-ignore
export const ToBeDeletedTermReportData = {
    average_senior_tue: 0,                          // 평균출석생 청장년 화
    average_senior_fri: 0,                          // 평균출석생 청장년 금
    average_senior_sat: 0,                          // 평균출석생 청장년 설교
    average_senior_sat_2: 0,                        // 평균출석생 청장년 2부
    
    average_student_tue: 0,                         // 평균출석생 중고생 화
    average_student_fri: 0,                         // 평균출석생 중고생 금
    average_student_sat: 0,                         // 평균출석생 중고생 설교
    average_student_sat_2: 0,                       // 평균출석생 중고생 2부
    
    average_child_tue: 0,                           // 평균출석생 어린이 화
    average_child_fri: 0,                           // 평균출석생 어린이 금
    average_child_sat: 0,                           // 평균출석생 어린이 설교
    average_child_sat_2: 0,                         // 평균출석생 어린이 2부
    
    sabbath_school_Attendance_2nd: 0,             // 2번째 안교 참여자 수
    sabbath_Attendance_2nd: 0,                    // 2번째 설교예배 참여자 수
    sabbath_school_Attendance_7th: 0,             // 7번째 안교 참여자 수
    sabbath_Attendance_7th: 0,                    // 7번째 설교예배 참여자 수
    
    baptized_senior: 0,                             // 수침자 장년
    baptized_youth: 0,                              // 수침자 청년
    baptized_student: 0,                            // 수침자 중고생
    baptized_child: 0,                              // 수침자 어린이

    restored_lost: 0,                               // 잃은양 회복수
    
    sabbath_school_member_more_then_71: 0,          // 안교생수 71세 이상
    sabbath_school_member_61_to_70: 0,              // 안교생수 61-70
    sabbath_school_member_51_to_60: 0,              // 안교생수 51-60
    sabbath_school_member_41_to_50: 0,              // 안교생수 41-50
    sabbath_school_member_31_to_40: 0,              // 안교생수 31-40
    sabbath_school_member_20_to_30: 0,              // 안교생수 20-30
    sabbath_school_member_17_to_19: 0,              // 안교생수 17-19
    sabbath_school_member_14_to_16: 0,              // 안교생수 14-16
    sabbath_school_member_11_to_13: 0,              // 안교생수 11-13
    sabbath_school_member_8_to_10: 0,               // 안교생수 8-10
    sabbath_school_member_4_to_7: 0,                // 안교생수 4-7
    sabbath_school_member_1_to_3: 0,                // 안교생수 1-3
    
    sabbath_school_member_baptism_class: 0,         // 안교생수 침례반
    sabbath_school_member_long_term_absentee: 0,    // 안교생수 장기결석자
    sabbath_school_member_lost: 0,                  // 안교생수 잃은양
};
//prettier-ignore
export const TermReportData = {
    baptize_members: 0,                             // 재적 침례교인수
    managed_members: 0,                             // 출석관리 교인수

    sabbath_school: 0,                              // 안식일학교 수
    sabbath_school_class: 0,                        // 안식일학교 반수
    sabbath_school_teacher: 0,                      // 안식일학교 교사수

    household_family: 0,                            // 교인세대수 가족
    household_single: 0,                            // 교인세대수 개인

    elder_men: 0,                                   // 장로 남
    elder_women: 0,                                 // 장로 여
    deacon: 0,                                      // 집사 남
    deaconess: 0,                                   // 집사 여

    teacher_association_operation: 0,               // 교사회 운영횟수
    average_studious: 0,                            // 평균공부자수

    small_group: 0,                                 // 소그룹 수
    active_small_group: 0,                          // 활동적인 소그룹 수
    disciple_training: false,                       // 제자훈련 실행여부
    evangelist: 0,                                  // 전도활동에 참여한 자 수
    laity_evangelical_meeting_and_seminar: 0,       // 평신도 전도회 및 세미나 수
    personal_bible_teach: 0,                        // 개인성경교수 수
    baptism_by_laity: 0,                            // 평신도 활동으로 침례받은 수
    tract_and_booklet: 0,                           // 전도지 소책자 전한 수
    evangelism_training_center: false,              // 전도훈련원 운영여부
    community_service_group: 0,                     // 지역사회 봉사 단체(반) 수

    child_evangelical_meeting: 0,                   // 어린이 전도회
    teen_evangelical_meeting: 0,                    // 청소년 전도회
    pastor_evangelical_meeting: 0,                  // 목회자 전도회
    small_group_evangelical_meeting: 0,             // 소그룹 전도회
    evangelist_group_evangelical_meeting: 0,        // 전도단 전도회
    other_evangelical_meeting: 0,                   // 기타 각종 전도회

    youth_group: 0,                                 // 청년선교회 조직수
    youth_group_members: 0,                         // 청년선교회 등록회원
    youth_group_attendance: 0,                      // 청년선교회 출석회원

    student_group: 0,                               // 학생회 조직수
    student_group_members: 0,                       // 학생회 등록회원
    student_group_attendance: 0,                    // 학생회 출석회원

    pathfinder: 0,                                  // 패스파인더 조직수
    pathfinder_members: 0,                          // 패스파인더 등록회원
    pathfinder_attendance: 0,                       // 패스파인더 출석회원

    student_room: false,                            // 청소년관 소유여부 
    student_teachers: 0,                            // 청소년 지도교사 수

    child_room: false,                              // 어린이관 소유여부
    child_teacher: 0,                               // 어린이 교사수
    child_sabbath_school: false,                    // 어린이 정규안교 개최여부
    child_tus_evening_service: false,               // 어린이 저녁예배 실시여부 화
    child_fri_evening_service: false,               // 어린이 저녁예배 실시여부 금
    child_bible_school: 0,                          // 어린이 성경학교 개최수
    child_bible_school_attendance: 0,               // 어린이 성경학교 참석자수

    reduce_sabbath_school_name: '',                 // 감소 안식일학교 이름
    reduce_sabbath_school_members_name: '',         // 감소 안식일학교 반생 이름
    increase_sabbath_school_name: '',               // 증가 안식일학교 이름
    increase_sabbath_school_members_name: '',       // 증가 안식일학교 반생 이름
};
