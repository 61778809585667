import axios from 'axios';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

// const firebaseConfig = { // ycms2 firebase config
//     apiKey: "AIzaSyBsBhaXKFtkxcixt_7_Cuz49RM6QnI2IJw",
//     authDomain: "ycms2-2871b.firebaseapp.com",
//     projectId: "ycms2-2871b",
//     storageBucket: "ycms2-2871b.appspot.com",
//     messagingSenderId: "410876452601",
//     appId: "1:410876452601:web:8749ad12555fefad3e0c28",
//     measurementId: "G-LGB02PLNYW"
// };
const firebaseConfig = {
  apiKey: 'AIzaSyCkBu9YiLcuayD9n6Wj6m_AXq9pXq45kjw',
  authDomain: 'ycms-a03b0.firebaseapp.com',
  projectId: 'ycms-a03b0',
  storageBucket: 'ycms-a03b0.appspot.com',
  messagingSenderId: '891623731541',
  appId: '1:891623731541:web:27201bb1154bb13d57bfdc',
  measurementId: 'G-60E0360PEE',
};

firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();

/**
 * 로그인을 한다.
 * @param {*} param0
 * @returns
 */
export const login = async ({ email, password }) => {
  auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
  const { user } = await auth.signInWithEmailAndPassword(email, password);

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_CMS_BACKEND_SERVER_URL}/auth/login`,
      {
        email,
        password: user.uid,
      },
      { withCredentials: true },
    );

    return { ...user, token: response.data.token };
  } catch (e) {
    console.log(e);
  }

  return user;
};

/**
 * 로그아웃을 한다.
 * @returns
 */
export const logout = async () => {
  sessionStorage.removeItem('userId');

  try {
    const response = await axios.post(`${process.env.REACT_APP_CMS_BACKEND_SERVER_URL}/auth/logout`, {});
  } catch (e) {
    console.log(e);
  }

  return await auth.signOut();
};

/**
 * 로그인 여부를 확인한다. by sesstionStorage
 * @returns boolean true, false
 */
export const isLogin = () => {
  return sessionStorage.getItem('userId') ? true : false;
};

/**
 * 로그인 사용자 아이디를 가지고 온다. by sessionStorage
 * @returns (세션에 저장된) 사용자 ID
 */
export const getLoginUserId = () => {
  return sessionStorage.getItem('userId');
};

/**
 * Firebase Authentication에서 사용자 정보를 가지고 온다.
 * @returns userId, name, email
 */
export const getCurrentUser = () => {
  if (!auth.currentUser) return;

  const { uid, displayName, email } = auth.currentUser;
  return { userId: uid, name: displayName, email };
};

/* [Admin ] ---------------------------------------------------------------------------- BEGIN */
/**
 * 넘어온 검색어(ChurchName)를 통하여 교회의 정보를 가지고 온다.
 * @param churchName 검색할 교회 이름
 * @returns 검색된 교회 리스트
 */
export const getChurchInfoByName = async ({ churchName }) => {
  const snapshot = await db.collection('churches').where('name', '==', churchName).limit(20).get();

  const list = [];
  if (!snapshot.empty) {
    snapshot.forEach((doc) => {
      list.push(doc.data());
    });
  }

  return list;
};
/* [Admin ] ---------------------------------------------------------------------------- END */
